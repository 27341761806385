<template>
  <!--润滑保养项目详情-->
  <div class="page">
    <van-nav-bar
      fixed
      :title="'润滑保养项目'"
      left-arrow
      @click-left="navBack"
    ></van-nav-bar>
    <div class="container">
      <van-form ref="form">
        <van-cell
          v-model="lubProjectData.projName"
          title="项目名称"
          maxlength="300"
        />
        <van-cell
          title="是否必输项"
          :value="lubProjectData.mustChk === 1 ? '是' : '否'"
        />
        <van-cell v-model="lubProjectData.projectDesc" title="位置" />
        <van-cell v-model="lubProjectData.dataTypeName" title="数据类型" />
        <!-- 标准值 -->
        <div>
          <van-field
            v-if="lubProjectData.dataType === 'String'"
            v-model="lubProjectData.standardValue"
            clickable
            required
            type="text"
            name="standardValue"
            label="标准值"
            placeholder="请填写标准值"
            input-align="right"
            :readonly="!editable"
            :rules="[{ required: true, trigger: 'onChange' }]"
            maxlength="100"
          >
          </van-field>
          <van-field
            v-else-if="lubProjectData.dataType === 'Int'"
            v-model="lubProjectData.standardValue"
            clickable
            required
            type="number"
            name="standardValue"
            label="标准值"
            placeholder="请填写标准值"
            input-align="right"
            :readonly="!editable"
            :rules="[{ required: true, trigger: 'onChange' }]"
          >
          </van-field>
          <van-field
            v-else-if="lubProjectData.dataType === 'Date'"
            v-model="lubProjectData.lubProjectData"
            clickable
            required
            name="lubProjectData"
            label="标准值"
            placeholder="点击选择标准值"
            input-align="right"
            :rules="[{ required: true, trigger: 'onChange' }]"
            :readonly="!editable"
            @click="dateTimePicker('lubProjectData')"
          >
          </van-field>
        </div>
        <van-cell v-model="lubProjectData.upValue" title="上限" />
        <van-cell v-model="lubProjectData.downValue" title="下限" />
        <van-cell v-model="lubProjectData.planRemark" title="计划备注" />

        <van-field
          clearable
          name="pd"
          label="是否实施"
          placeholder="请选择"
          error-message-align="right"
          input-align="right"
          :readonly="!editable"
        >
          <template #input>
            <van-radio-group v-model="lubProjectData.pb" direction="horizontal">
              <van-radio name="1">是</van-radio>
              <van-radio name="0">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="lubProjectData.remark"
          clickable
          type="textarea"
          name="lubProjectData"
          label="实施备注"
          placeholder="请填写实施备注"
          input-align="right"
          maxlength="500"
          show-word-limit
          :readonly="!editable"
        >
        </van-field>
      </van-form>
      <bottom-fixed-box>
        <van-button v-if="editable" type="info" @click="save()">
          保存
        </van-button>
      </bottom-fixed-box>
    </div>
    <!--时间选择器-->
    <van-popup v-model="showDateTimePicker" position="bottom">
      <van-datetime-picker
        title="选择年月日小时"
        type="datehour"
        @confirm="onDateTimePickerConfirm"
        @cancel="showDateTimePicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { formatDates } from "@/views/accidentReport/utils/formDate";
import BottomFixedBox from "@/components/general/BottomFixedBox";
import { updateLubImelpment } from "@/views/deviceLubImplement/api";

export default {
  name: "LubProjectDetail",
  components: {
    BottomFixedBox
  },
  props: {
    form: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: function() {
        return {};
      }
    },
    idx: {
      type: Number
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      lubProjectData: {
        pb: 0
      },
      showDateTimePickerKey: "",
      showDateTimePicker: false
    };
  },

  created() {},
  mounted() {
    // 等待整个视图渲染完毕
    this.$nextTick(() => {
      this.lubProjectData = this.form.mxList[this.idx];
      this.lubProjectData.pb = this.lubProjectData.pb.toString();
    });
  },
  methods: {
    save() {
      this.$refs.form.validate().then(async () => {
        await updateLubImelpment(this.form);
        this.$toast.success("保存成功！");
        this.$router.back();
      });
    },
    dateTimePicker(key) {
      if (!this.editable) return;
      this.showDateTimePickerKey = key;
      this.showDateTimePicker = true;
    },
    onDateTimePickerConfirm(val) {
      let datetime = formatDates(val, "yyyy-MM-dd hh:mm");
      this.$set(this.lubProjectData, this.showDateTimePickerKey, datetime);
      this.showDateTimePicker = false;
    },
    navBack() {
      if (this.editable) {
        this.$dialog
          .confirm({
            title: "提醒",
            message: "数据尚未保存，确认返回？"
          })
          .then(() => {
            this.$router.back();
          });
        return;
      }
      this.$router.back();
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
}

.container {
  height: 750px;
  margin-top: 58px;
}
</style>
