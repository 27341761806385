import axios from "@/utils/axios";
import apiPath from "@/utils/apiPath";

const API_SERVE = apiPath.equipment;
//查询润滑保养列表
export const getDeviceLubImplementList = params => {
  return axios.get(API_SERVE + "/page/equipMaintenanExec/getList", { params });
};
//保存或提交
export const save = (controller, params) => {
  return axios.post(API_SERVE + `/phone/${controller}/maintain`, params);
};

// 查询润滑保养详情
export const getDeviceLubImplementInfo = id => {
  return axios.get(
    API_SERVE + `/get/equipMaintenanExec/getEquipMaintenanExec/${id}`
  );
};

export const getBasicParams = code => {
  return axios.get(API_SERVE + `/list/Base/queryList/${code}`);
};

//获取备品备件
export const getSpareparts = code => {
  return axios.get(
    API_SERVE + `/page/spareparts/itemmaster?page=1&size=9999&status=1`
  );
};

// 更新润滑保养实施
export const updateLubImelpment = params => {
  return axios.post(
    API_SERVE + "/update/equipMaintenanExec/updateEquipMaintenanExec",
    params
  );
};
